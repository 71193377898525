import { Controller } from "@hotwired/stimulus";
import { isMobile } from "react-device-detect";

export default class extends Controller {
  static targets = ["shareButton"];
  static values = {
    url: String,
    title: String,
    text: String,
  };

  connect() {
    this.checkShareSupport();
  }

  checkShareSupport() {
    const canShare =
      navigator.canShare && navigator.canShare({ url: this.urlValue });

    if (isMobile && canShare) {
      this.shareButtonTarget.classList.remove("hidden");
    } else {
      this.shareButtonTarget.classList.add("hidden");
    }
  }

  async share() {
    if (navigator.canShare && navigator.canShare({ url: this.urlValue })) {
      try {
        const shareData = {
          url: this.urlValue,
        };

        if (this.hasTitleValue) {
          shareData.title = this.titleValue;
        }

        if (this.hasTextValue) {
          shareData.text = this.textValue;
        }

        await navigator.share(shareData);
        console.log("Shared successfully");
      } catch (err) {
        console.error("Error sharing:", err);
      }
    } else {
      console.warn("Sharing not supported on this device/browser");
    }
  }
}
