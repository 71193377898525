import usePersistentStore from "../store/PersistentStore";
import useGameStore from "../store/GameStore";

export function postSubmission(newPicks = null) {
  const picks = newPicks || useGameStore.getState().picks;

  const url = `api/submit`;
  const data = {
    browserId: usePersistentStore.getState().browserId,
    picks: picks,
    roundNumber: useGameStore.getState().round.number,
  };
  console.log(data);
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    // credentials: "include",
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Submission response was not ok.");
      }

      return response.json();
    })
    .then((data) => {
      // Handle the response data here (if needed)
      console.log("Response:", data);
      return data;
    });
  // .catch((error) => {
  //   // Handle any error that occurred during the fetch
  //   console.error("Error:", error);
  // });
}
