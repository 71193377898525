import React from "react";
import { BaseDialog } from "../BaseDialog";
import useStimulusStore from "../../lib/store/StimulusStore";

function StimulusDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useStimulusStore((state) => [
    state.isModalOpen,
    state.setIsModalOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={false}
        modalBody={<ModalBody closeModal={closeModal} />}
        size="md"
        showClose={false}
      />
    </>
  );
}

function ModalBody({ closeModal }) {
  const [modalBody] = useStimulusStore((state) => [state.modalBody]);

  return <div dangerouslySetInnerHTML={{ __html: modalBody }} />;
}

export default StimulusDialog;
