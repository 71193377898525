import controller_0 from '@symfony/ux-autocomplete/dist/controller.js';
import './styles/tom-select.css';
import controller_1 from '@symfony/ux-dropzone/dist/controller.js';
import '@symfony/ux-dropzone/dist/style.min.css';
import controller_2 from '@symfony/ux-react/dist/render_controller.js';
import controller_3 from '@symfony/ux-turbo/dist/turbo_controller.js';
export default {
  'symfony--ux-autocomplete--autocomplete': controller_0,
  'symfony--ux-dropzone--dropzone': controller_1,
  'symfony--ux-react--react': controller_2,
  'symfony--ux-turbo--turbo-core': controller_3,
};