import { Controller } from "@hotwired/stimulus";

/*
 * Used on login form.
 *
 * e.g. <form data-controller="login"></form>
 *
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:before-fetch-response", (event) => {
      console.log(event.detail);
      // event.preventDefault();
    });
    this.element.addEventListener("turbo:submit-end", (event) => {
      console.log(event.detail);
      if (event.detail.success) {
        if (typeof window.SnackBar !== "undefined") {
          window.SnackBar.postMessage("Logged in successfully");
        }
      }

      // event.preventDefault();
    });
  }
}

function getCookie(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
}
