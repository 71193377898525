import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["team", "saveButton", "resetButton"];

  initialize() {
    this.selectedTeam = null;
    this.originalTeamIds = this.teamTargets.map((team) => team.dataset.teamId);
    this.originalTeamHTML = this.teamTargets.map((team) => team.innerHTML);
  }

  selectTeam(event) {
    const clickedTeam = event.currentTarget;

    if (this.selectedTeam) {
      // Swap teams
      const tempHTML = clickedTeam.innerHTML;
      const tempId = clickedTeam.dataset.teamId;

      clickedTeam.innerHTML = this.selectedTeam.innerHTML;
      clickedTeam.dataset.teamId = this.selectedTeam.dataset.teamId;

      this.selectedTeam.innerHTML = tempHTML;
      this.selectedTeam.dataset.teamId = tempId;

      // Check if teams are in their original positions
      this.teamTargets.forEach((team, index) => {
        if (team.dataset.teamId === this.originalTeamIds[index]) {
          team.classList.remove("bg-yellow-100");
        } else {
          team.classList.add("bg-yellow-100");
        }
      });

      // Reset selected team
      this.selectedTeam.classList.remove("bg-blue-100");
      this.selectedTeam = null;
    } else {
      // Select a team
      if (this.selectedTeam) {
        this.selectedTeam.classList.remove("bg-blue-100");
      }
      this.selectedTeam = clickedTeam;
      clickedTeam.classList.add("bg-blue-100");
    }
  }

  saveMatchups() {
    const matchups = [];
    const teams = this.teamTargets;

    for (let i = 0; i < teams.length; i += 2) {
      matchups.push({
        team1: teams[i].dataset.teamId,
        team2: teams[i + 1].dataset.teamId,
      });
    }

    console.log(JSON.stringify(matchups));

    // Update the hidden input field
    const fixtureJsonInput = document.getElementById(
      "round_fixtures_form_fixtureJson",
    );
    fixtureJsonInput.value = JSON.stringify(matchups);

    // Submit the form
    fixtureJsonInput.form.submit();
  }

  resetTeams() {
    this.teamTargets.forEach((team, index) => {
      team.innerHTML = this.originalTeamHTML[index];
      team.dataset.teamId = this.originalTeamIds[index];
      team.classList.remove("bg-yellow-100", "bg-blue-100");
    });
    this.selectedTeam = null;
  }
}
