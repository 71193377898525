import { createWithEqualityFn } from "zustand/traditional";

const useGameStore = createWithEqualityFn(
  (set, get) => ({
    init: false,
    setInit: (init) => {
      set({ init: init });
    },

    players: null,
    setPlayers: (players) => {
      set({ players: players });
    },

    currentPick: 1,
    setCurrentPick: (pick) => {
      set({ currentPick: pick });
    },

    picks: {},
    setPicks: (picks) => {
      set({ picks: picks });
    },
    persistPick: (overallPick, player) => {
      set((prevState) => {
        const updatedPicks = Object.assign({}, prevState.picks);
        updatedPicks[overallPick] = player?.id;

        return {
          picks: updatedPicks,
          currentPick: Object.keys(updatedPicks).length + 1,
        };
      });
    },
    resetPicks: async () => {
      set(() => ({
        picks: {},
        currentPick: 1,
      }));
    },

    pickResults: null,
    setPickResults: (picks) => {
      set({ pickResults: picks });
    },

    submission: null,
    setSubmission: (submission) => {
      set({ submission: submission });
    },

    lockState: "PRE",
    setLockState: (lockState) => {
      set({ lockState: lockState });
    },

    initialOrderByTeamId: null,
    setInitialOrderByTeamId: (order) => {
      set({ initialOrderByTeamId: order });
    },

    orderByTeamId: null,
    setOrderByTeamId: async (order) => {
      set({ orderByTeamId: order });
    },

    contest: null,
    setContest: (contest) => {
      set({ contest: contest });
    },

    round: null,
    setRound: (round) => {
      set({ round: round });
    },

    currentRound: null,
    setCurrentRound: (round) => {
      set({ currentRound: round });
    },

    teams: null,
    setTeams: (teams) => {
      set({ teams: teams });
    },

    scores: null,
    setScores: (scores) => {
      set({ scores: scores });
    },

    avgPoints: null,
    setAvgPoints: (points) => {
      set({ avgPoints: points });
    },

    fixtures: null,
    setFixtures: (fixtures) => {
      set({ fixtures: fixtures });
    },

    initError: false,
    setInitError: (value) => set({ initError: value }),

    user: null,
    isUserLoading: true,
    setUser: (user) => set({ user: user }),

    selectedTab: "picks",
    setSelectedTab: (tab) => set((state) => ({ selectedTab: tab })),

    isShareOpen: false,
    setIsShareOpen: (isOpen) => set((state) => ({ isShareOpen: isOpen })),

    isRegisterOpen: false,
    setIsRegisterOpen: (isOpen) => set((state) => ({ isRegisterOpen: isOpen })),

    isPreselectionOpen: false,
    setIsPreselectionOpen: (isOpen) =>
      set((state) => ({ isPreselectionOpen: isOpen })),
    preselectionPick: null,
    setPreselectionPick: (pick) => set((state) => ({ preselectionPick: pick })),

    isHelpOpen: false,
    setIsHelpOpen: (isOpen) => set((state) => ({ isHelpOpen: isOpen })),

    showShareDiv: false,
    setShowShareDiv: (show) => set((state) => ({ showShareDiv: show })),

    shareMode: null,
    setShareMode: (mode) => set((state) => ({ shareMode: mode })),

    shareJpegData: null,
    setShareJpegData: (data) => set((state) => ({ shareJpegData: data })),

    shareGroup: 1,
    setShareGroup: (group) => set((state) => ({ shareGroup: group })),

    setupLevel: () => {
      get().fetchUser();
    },
  }),
  Object.is,
);

export default useGameStore;
