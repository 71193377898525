import { createWithEqualityFn } from "zustand/traditional";

const useStimulusStore = createWithEqualityFn(
  (set, get) => ({
    modalHeader: null,
    setModalHeader: (header) => {
      set({ modalHeader: header });
    },

    modalBody: null,
    setModalBody: (body) => {
      set({ modalBody: body });
    },

    isModalOpen: false,
    setIsModalOpen: (isOpen) => set((state) => ({ isModalOpen: isOpen })),
  }),
  Object.is,
);

export default useStimulusStore;
