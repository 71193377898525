import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog", "inner", "dynamicContent", "loadingContent"];

  observer = null;
  formSubmissionCount = 0;

  connect() {
    if (this.hasDynamicContentTarget) {
      // when the content changes, call this.open()
      this.observer = new MutationObserver(() => {
        setTimeout(() => {
          const shouldOpen =
            this.dynamicContentTarget.innerHTML.trim().length > 0;
          if (shouldOpen && !this.dialogTarget.open) {
            this.open();
          } else if (!shouldOpen && this.dialogTarget.open) {
            this.close();
          }
        }, 50); // 50ms delay to help race conditions
      });

      this.observer.observe(this.dynamicContentTarget, {
        childList: true,
        characterData: true,
        subtree: true,
      });
    }

    // Listen for Turbo frame changes
    document.addEventListener(
      "turbo:submit-start",
      this.handleFormSubmit.bind(this),
    );
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.dialogTarget.open) {
      this.close();
    }
    document.removeEventListener("turbo:submit-start", this.handleFormSubmit);
  }

  open() {
    this.dialogTarget.showModal();
    document.body.classList.add("overflow-hidden");

    // Reset the turbo frame load flag
    this.formSubmissionCount = 0;
  }

  close() {
    this.dialogTarget.close();
    document.body.classList.remove("overflow-hidden");

    // Clear the dynamic content
    this.dynamicContentTarget.innerHTML = "";

    // Check if a Turbo frame has been `loaded since the modal was opened
    if (this.formSubmissionCount > 0) {
      // Refresh the page
      window.location.reload();
    }

    // Reset the flag
    this.formSubmissionCount = 0;
  }

  clickOutside(event) {
    const formExists = this.dialogTarget.querySelector("form");

    if (formExists) {
      return;
    }

    if (
      event.target === this.dialogTarget ||
      event.target === this.innerTarget
    ) {
      this.dialogTarget.close();
    }
  }

  showLoading() {
    // do nothing if the dialog is already open
    if (this.dialogTarget.open) {
      return;
    }

    this.dynamicContentTarget.innerHTML = this.loadingContentTarget.innerHTML;
  }

  handleFormSubmit() {
    // Set the flag to indicate that a Turbo frame has been loaded since the modal was opened
    this.formSubmissionCount++;
  }
}
