import { Controller } from "@hotwired/stimulus";
import useStimulusStore from "../react/global/lib/store/StimulusStore";
import { fetchText } from "../react/global/lib/utils/fetch";

export default class extends Controller {
  static values = {
    modalPath: String,
  };

  connect() {
    console.log("modals loaded");
    // ReactDOM.render(<PreselectionDialog />, this.element);
  }

  async open(event) {
    const html = await fetchText(
      this.modalPathValue + "/" + event.params.submissionId,
    );

    // useGameStore.getState().setIsPreselectionOpen(true);
    useStimulusStore.getState().setModalBody(html);
    useStimulusStore.getState().setIsModalOpen(true);
  }
}
