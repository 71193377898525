import useGameStore from "../lib/store/GameStore";
import React, { useState } from "react";
import SearchDialog from "./modals/SearchDialog";
import { getPlayerById } from "../lib/utils/helpers";
import { IconEdit } from "../../global/components/icons/IconEdit";

export function BoardRow({ pick, overallPick, lockState }) {
  const [setIsHelpOpen, picks, currentPick, roundPoints] = useGameStore(
    (state) => [
      state.setIsHelpOpen,
      state.picks,
      state.currentPick,
      state.scores,
    ],
  );

  const onTheClock = lockState !== "COMPLETE" && overallPick === currentPick;
  const selectedPlayerId = picks[overallPick];
  const selectedPlayer = getPlayerById(selectedPlayerId);
  const points = roundPoints && roundPoints[selectedPlayer?.id];

  const hasPoints = selectedPlayer && points > 0;
  const isUnlocked = onTheClock || (selectedPlayer && !hasPoints);

  function handleClick() {
    console.log("openhelp");
    setIsHelpOpen(true);
  }

  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    if (isUnlocked) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }

  const ContainerTag = isUnlocked ? "button" : "div";

  return (
    <>
      <ContainerTag
        onClick={openModal}
        disabled={!isUnlocked}
        className={`flex w-full group items-center transition-transform ${
          onTheClock ? "hover:scale-[1.01] active:scale-[.98]" : ""
        }`}
      >
        <div
          className={`w-full ${
            onTheClock
              ? "bg-gradient-to-r from-[#574528] to-[#edc579]"
              : "bg-white"
          }`}
        >
          <div className="relative flex items-center">
            <div className="flex-none relative flex h-16">
              <div
                id="rank"
                className={`relative flex w-14 flex-none items-center justify-center font-oswald text-3xl font-bold tracking-tight ${
                  onTheClock ? "text-white" : "text-[#574528]"
                } antialiased`}
              >
                {overallPick}
                {/*<div className="absolute bottom-0 font-oswald text-xs text-lime-600">*/}
                {/*  +10pts*/}
                {/*</div>*/}
              </div>
            </div>
            {selectedPlayer && (
              <div className="flex-none">
                <img
                  className="mr-2 h-16 w-16"
                  src={`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/2024014/${selectedPlayer.dataId}.png?im=Scale,width=.3,height=.3`}
                  alt={`${selectedPlayer.name} headshot`}
                />
              </div>
            )}
            <div id="name" className="flex-1 text-left items-center min-w-0">
              {selectedPlayer ? (
                <>
                  <div className="min-w-0 truncate text-xl font-medium uppercase leading-none text-stone-900 antialiased">
                    {selectedPlayer.firstName}
                  </div>
                  <div className="min-w-0 truncate font-saira text-3xl font-bold uppercase leading-none text-stone-900 antialiased">
                    {selectedPlayer.lastName}
                  </div>
                </>
              ) : onTheClock ? (
                <div className="flex items-center text-left text-lg font-semibold uppercase text-white">
                  <span>SELECT A PLAYER</span>
                  <span className="relative ml-2 flex h-3 w-3">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-white opacity-75"></span>
                    <span className="relative inline-flex h-3 w-3 rounded-full bg-yellow-100"></span>
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
            {hasPoints ? (
              <div className="flex flex-col pr-1 text-right text-slate-950 antialiased">
                <span className="pt-1 font-saira text-3xl font-bold uppercase leading-none">
                  {points}
                </span>
              </div>
            ) : (
              selectedPlayer && (
                <>
                  <div className="flex justify-end text-right antialiased">
                    <div className="p-1 text-slate-400 group-hover:text-slate-950">
                      <IconEdit className="h-7 w-7" />
                    </div>
                  </div>
                </>
              )
            )}

            {selectedPlayer && hasPoints && (
              <div className="absolute right-0 top-0 z-20 flex items-center justify-center rounded-bl-md bg-lime-600 px-1.5 py-0.5 font-redhat text-[0.5825rem] font-medium leading-none text-white">
                <span className="mr-0">
                  +{(5 - overallPick + 1) * points} PTS
                </span>
              </div>
            )}
            {selectedPlayer && !roundPoints && (
              <div className="absolute right-0 top-0 z-20 flex items-center justify-center rounded-bl-md bg-lime-600/90 px-1.5 py-0.5 font-redhat text-[0.5825rem] font-medium leading-none text-white">
                <span className="mr-0">x{5 - overallPick + 1} PTS</span>
              </div>
            )}
          </div>
        </div>
      </ContainerTag>
      <SearchDialog
        overallPick={overallPick}
        isOpen={isOpen}
        closeModal={closeModal}
        selectedPlayer={selectedPlayer}
      />
    </>
  );
}
