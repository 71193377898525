import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const input = this.element.querySelector("input");
    this.updateButtonState(input);
  }

  increment(event) {
    this.updateValue(event.currentTarget, 1);
  }

  decrement(event) {
    this.updateValue(event.currentTarget, -1);
  }

  updateValue(button, change) {
    const input = this.getInputField(button);
    const min = parseInt(input.dataset.counterinputMin, 10);
    const max = parseInt(input.dataset.counterinputMax, 10);
    const value = parseInt(input.value, 10) || 0;

    let newValue = value + change;
    if (newValue < min) newValue = min;
    if (newValue > max) newValue = max;

    input.value = newValue;
    this.updateButtonState(input);

    // Dispatch input and change events
    input.dispatchEvent(new Event("input", { bubbles: true }));
    input.dispatchEvent(new Event("change", { bubbles: true }));
  }

  updateButtonState(input) {
    const value = parseInt(input.value, 10) || 0;
    const min = parseInt(input.dataset.counterinputMin, 10);
    const max = parseInt(input.dataset.counterinputMax, 10);

    const decrementButton = input.previousElementSibling;
    const incrementButton = input.nextElementSibling;

    decrementButton.disabled = value <= min;
    incrementButton.disabled = value >= max;
  }

  getInputField(button) {
    return button.closest("div").querySelector("input");
  }
}
