import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import { generateId } from "../../../global/lib/utils/generateId";

const usePersistentStore = createWithEqualityFn(
  persist(
    (set, get) => ({
      browserId: generateId(),

      instructionsDismissed: false,
      saveInstructionsDismissed: (isDismissed) =>
        set(() => ({
          instructionsDismissed: isDismissed,
        })),
    }),
    {
      name: "captains",
    }
  ),
  Object.is
);

export default usePersistentStore;
