import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  constructor(...args) {
    super(...args);
    this.saveCheckboxStates = this.saveCheckboxStates.bind(this);
    this.restoreCheckboxStates = this.restoreCheckboxStates.bind(this);
  }

  connect() {
    this.checkboxStates = new Map();

    this.element.addEventListener(
      "turbo:before-frame-render",
      this.saveCheckboxStates,
    );
    this.element.addEventListener(
      "turbo:frame-render",
      this.restoreCheckboxStates,
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-frame-render",
      this.saveCheckboxStates,
    );
    this.element.removeEventListener(
      "turbo:frame-render",
      this.restoreCheckboxStates,
    );
  }

  saveCheckboxStates(event) {
    console.log("Saving checkbox states");
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      this.checkboxStates.set(checkbox.id, checkbox.checked);
    });
  }

  restoreCheckboxStates(event) {
    console.log("Restoring checkbox states");
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (this.checkboxStates.has(checkbox.id)) {
        checkbox.checked = this.checkboxStates.get(checkbox.id);
      }
    });
    this.checkboxStates.clear();
  }
}
