import { Controller } from "@hotwired/stimulus";

/*
 * Used on theme toggle button.
 *
 * e.g. <button data-controller="themetoggle">Toggle Theme</button>
 *
 */
export default class extends Controller {
  connect() {
    const tableWrapper = this.element;

    // Select the tableContainer and tableHeader elements
    const tableContainer = tableWrapper.querySelector("#tableContainer");
    const tableHeader = tableWrapper.querySelector("#tableHeader");

    // Listen for the scroll event on tableContainer
    tableContainer.addEventListener("scroll", function () {
      // Get the current horizontal scroll position
      const scrollLeft = tableContainer.scrollLeft;

      // Set the transform property of tableHeader to translate it horizontally
      tableHeader.style.transform = `translateX(${-scrollLeft}px)`;
    });
  }
}
