// controllers/player_market_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table", "paginationLink"];

  shouldScroll = false;

  connect() {
    document.addEventListener("turbo:frame-load", this.handleFrameLoad);
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.handleFrameLoad);
  }

  handlePaginationClick = () => {
    this.shouldScroll = true;
  };

  handleFrameLoad = (event) => {
    if (event.target.id === "stat-content" && this.shouldScroll) {
      this.scrollToTable();
      this.shouldScroll = false; // Reset the flag
    }
  };

  scrollToTable() {
    if (this.hasTableTarget) {
      this.tableTarget.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
}
