import { toCanvas } from "html-to-image";

export async function htmlToJpegDataUrl() {
  const shareDiv = document.getElementById("shareDiv");

  try {
    await toCanvas(shareDiv, {
      cacheBust: false,
      pixelRatio: 6,
    });
    await toCanvas(shareDiv, {
      cacheBust: false,
      pixelRatio: 6,
    });
    const canvas = await toCanvas(shareDiv, {
      cacheBust: false,
      pixelRatio: 6,
      backgroundColor: "#FFFFFF",
    });

    return canvas.toDataURL("image/jpeg");
  } catch (err) {
    console.error(err);

    throw err;
  }
}

export async function htmlToCanvas() {
  const shareDiv = document.getElementById("shareDiv");
  try {
    await toCanvas(shareDiv, {
      cacheBust: false,
      pixelRatio: 6,
    });
    await toCanvas(shareDiv, {
      cacheBust: false,
      pixelRatio: 6,
    });
    return await toCanvas(shareDiv, {
      cacheBust: false,
      pixelRatio: 6,
      backgroundColor: "#FFFFFF",
      height: 256,
    });
  } catch (err) {
    console.error(err);

    throw err;
  }
}
