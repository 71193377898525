import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const tableWrapper = this.element;
    const tableContainer = tableWrapper.querySelector("#tableContainer");
    const tableHeader = tableWrapper.querySelector("#tableHeader");
    const mainTableContent = tableWrapper.querySelector("#mainTableContent");

    this.bindHeaderWidths();
    this.bindScrolling(tableContainer, tableHeader);

    // Observe changes in the mainTableContent
    const observer = new MutationObserver(() => this.bindHeaderWidths());
    observer.observe(mainTableContent, { subtree: true, childList: true });

    // Bind widths on window resize
    window.addEventListener("resize", () => this.bindHeaderWidths());
  }

  bindHeaderWidths() {
    const mainTableContent = this.element.querySelector("#mainTableContent");
    const tableHeader = this.element.querySelector("#tableHeader");

    const tbody = mainTableContent.querySelector("tbody");
    if (!tbody) return;

    // Skip binding if tbody has 'no-players' class
    if (tbody.classList.contains("no-players")) return;

    const contentCells = tbody
      .querySelector("tr:first-child")
      ?.querySelectorAll("td");
    const headerCells = tableHeader.querySelectorAll("th");

    if (contentCells && headerCells) {
      contentCells.forEach((cell, index) => {
        if (headerCells[index]) {
          const width = cell.offsetWidth;
          headerCells[index].style.width = `${width}px`;
          headerCells[index].style.minWidth = `${width}px`;
        }
      });
    }
  }

  bindScrolling(tableContainer, tableHeader) {
    tableContainer.addEventListener("scroll", () => {
      const scrollLeft = tableContainer.scrollLeft;
      tableHeader.style.transform = `translateX(${-scrollLeft}px)`;
    });
  }
}
