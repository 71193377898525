import React, { useEffect } from "react";
import Board from "../components/Board";
import useGameStore from "../lib/store/GameStore";
import { ShareDiv } from "../components/ShareDiv";
import PreselectionDialog from "../components/modals/PreselectionDialog";
import ShareDialog from "../components/modals/ShareDialog";
import { isDesktop } from "react-device-detect";
import { canShareImage } from "../../global/lib/utils/sharePermissions";
import { trackEvent } from "../lib/utils/analytics";
import usePersistentStore from "../lib/store/PersistentStore";
import { postSubmission } from "../lib/utils/submit";
import { fetchUrl } from "../../global/lib/utils/fetch";

export default function (props) {
  const [
    setUser,
    setPlayers,
    setLockState,
    setSubmission,
    setPicks,
    setCurrentPick,
    setContest,
    setRound,
    setFixtures,
    setCurrentRound,
    setScores,
    setAvgPoints,
    setShareMode,
    selectedTab,
    setSelectedTab,
    setIsPreselectionOpen,
    setInit,
    adReady,
  ] = useGameStore((state) => [
    state.setUser,
    state.setPlayers,
    state.setLockState,
    state.setSubmission,
    state.setPicks,
    state.setCurrentPick,
    state.setContest,
    state.setRound,
    state.setFixtures,
    state.setCurrentRound,
    state.setScores,
    state.setAvgPoints,
    state.setShareMode,
    state.selectedTab,
    state.setSelectedTab,
    state.setIsPreselectionOpen,
    state.setInit,
    state.init,
  ]);

  const [browserId] = usePersistentStore((state) => [state.browserId]);

  async function init() {
    console.log(props);
    setSelectedTab(props.tab);
    setLockState(props.state);
    setUser(props.user);
    setContest(props.contest);
    setRound(props.contest.round);
    setFixtures(props.fixtures);
    setScores(props.scoresMap);
    setAvgPoints(props.seasonAvg);
    setCurrentRound(props.currentRound);
    if (props.submission) {
      setSubmission(props.submission);
      setPicks(props.submission.picks);
      setCurrentPick(props.submission.picks.length + 1);
    } else {
      if (!props.user) {
        // setIsPreselectionOpen(true);
      }

      // Check if submission exists for Browser ID and if so sync
      const browserSubmission = await fetchBrowserId(
        props.contest.round.number,
        browserId,
      );

      if (browserSubmission?.submission) {
        setPicks(browserSubmission.submission.picks);
        setCurrentPick(browserSubmission.submission.picks.length + 1);

        if (props.user) {
          // Resubmit now user is logged in
          postSubmission();
        }
      }
    }
    setInit(true);

    // Sort players by selectedPercentage then by season avg
    const sortedPlayers = props.players.sort((a, b) => {
      const selectionsA = props.contest.stats.selections[a.id] || 0;
      const selectionsB = props.contest.stats.selections[b.id] || 0;

      if (selectionsA !== selectionsB) {
        return selectionsB - selectionsA; // Sort by selected percentage descending
      } else {
        const seasonAvgA = parseFloat(props.seasonAvg[a.id] || 0);
        const seasonAvgB = parseFloat(props.seasonAvg[b.id] || 0);
        return seasonAvgB - seasonAvgA; // Sort by season average descending
      }
    });

    setPlayers(sortedPlayers);
  }

  // Set up game
  useEffect(() => {
    // console.log("Init");
    init();
  }, []);

  async function checkShareSupport() {
    if (isDesktop) {
      setShareMode("download");
    } else {
      const result = await canShareImage();
      setShareMode(result ? "share" : "download");
    }
  }

  useEffect(() => {
    checkShareSupport();
  }, []);

  function onTabClick(tab) {
    const state = { tab: tab };
    const url = `${state.tab}`;
    window.history.pushState(state, "", url);

    setSelectedTab(tab);

    trackEvent(`tab_${tab}`);
  }

  return (
    <>
      {/*<TabBar onTabClick={onTabClick} selectedTab={selectedTab} />*/}
      <Board {...props} />
      {/*<HowToPlayDialog />*/}
      <PreselectionDialog />
      <ShareDialog />
      <ShareDiv />
      {adReady && (
        <div className="sticky bottom-2 left-0 right-0 mx-auto mb-4 h-[50px] max-h-[50px] max-w-screen-lg text-center sm:h-[90px] sm:max-h-[90px] md:mb-12">
          <a
            href="https://keeperfantasy.com/?utm_source=finals&utm_medium=affiliate&utm_campaign=banner"
            target="_blank"
          >
            <img
              className="mx-auto h-[50px] max-h-[50px] sm:hidden"
              src="/build/images/finals/promo/keeper-prereg-banner-mobile.png"
              alt="Pre-Register for Keeper"
            />
            <img
              className="mx-auto hidden sm:block sm:h-[90px] sm:max-h-[90px]"
              src="/build/images/finals/promo/keeper-prereg-banner-mobile.png"
              alt="Pre-Register for Keeper"
            />
          </a>
        </div>
      )}
    </>
  );
}

async function fetchBrowserId(roundNumber, browserId) {
  try {
    return await fetchUrl(`api/browser/${roundNumber}/${browserId}`);
  } catch (error) {
    console.log(error.message);
  }
}
