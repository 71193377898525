export async function shareImage(dataUrl, fileName = "share.jpg") {
  try {
    const blob = await (await fetch(dataUrl)).blob();

    const filesArray = [
      new File([blob], fileName, {
        type: blob.type,
        lastModified: new Date().getTime(),
      }),
    ];

    const shareData = {
      files: filesArray,
    };

    await navigator.share(shareData);
    console.log("Shared successfully");
  } catch (err) {
    console.error(err);
  }
}
