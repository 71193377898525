// pick_trades_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Get the checkbox element
    this.checkbox = this.element.querySelector("#future_picks_form_pickTrades");

    // Get the seasons field container div using the new id
    this.seasonsField = this.element.querySelector("#future-seasons");

    // Set visibility based on checkbox state
    this.toggleSeasonsField();

    // Add event listener
    this.checkbox.addEventListener("change", () => this.toggleSeasonsField());
  }

  toggleSeasonsField() {
    if (this.checkbox.checked) {
      this.seasonsField.classList.remove("hidden");
    } else {
      this.seasonsField.classList.add("hidden");
    }
  }

  disconnect() {
    // Clean up event listener
    this.checkbox.removeEventListener("change", () =>
      this.toggleSeasonsField(),
    );
  }
}
