// global_tooltip_controller.js
import { Controller } from "@hotwired/stimulus";
import tippy, { hideAll } from "tippy.js";
import "tippy.js/animations/shift-away-subtle.css";

export default class extends Controller {
  connect() {
    this.initializeTooltips();
    document.addEventListener(
      "turbo:render",
      this.initializeTooltips.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener(
      "turbo:render",
      this.initializeTooltips.bind(this),
    );
  }

  initializeTooltips() {
    // Destroy existing tooltips
    hideAll();

    // Initialize new tooltips
    tippy("[data-tooltip]", {
      content: (reference) => reference.getAttribute("data-tooltip"),
      placement: "top",
      arrow: true,
      animation: "shift-away-subtle",
      interactive: false,
      allowHTML: true,
      delay: [50, 0],
      theme: "custom",
      onShow(instance) {
        instance.popper
          .querySelector(".tippy-box")
          .classList.add(
            "bg-gray-800",
            "text-white",
            "dark:bg-gray-700",
            "text-sm",
          );
        instance.popper
          .querySelector(".tippy-arrow")
          .classList.add("text-gray-800", "dark:text-gray-700");
      },
    });
  }
}
