import { Controller } from "@hotwired/stimulus";

/*
 * Used to scroll an element to the center of a container.
 *
 * e.g. <nav data-controller="navbar"></nav>
 *
 */
export default class extends Controller {
  connect() {
    const navbar = this.element;
    const activePill = navbar.querySelector(
      '[data-navbar-target="active-pill"]',
    );

    if (activePill) {
      activePill.scrollIntoView({ block: "nearest", inline: "center" });
    }
    navbar.classList.replace("opacity-0", "opacity-100");
  }
}
