import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.element.addEventListener(
      "lineupEvent",
      this.handleLineupEvent.bind(this),
    );

    const turboFrame = document.getElementById("lineup-frame");
    if (turboFrame) {
      turboFrame.addEventListener(
        "turbo:frame-load",
        this.handleFrameLoad.bind(this),
      );
    }
  }

  async handleLineupEvent(event) {
    console.log("Lineup event detected", event.detail);

    await new Promise((resolve) => setTimeout(resolve, 500));

    this.submitForm();
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  handleFrameLoad(event) {
    // After form frame loads after submit, dispatch a custom event to close the bottom sheet
    const closeSheetEvent = new CustomEvent("closeSheet", { detail: {} });
    document.dispatchEvent(closeSheetEvent);
  }
}
