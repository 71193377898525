import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollable"];
  scrollPosition = 0;

  connect() {
    this.loadScrollPosition();
    this.element.addEventListener(
      "turbo:before-fetch-request",
      this.saveScrollPosition.bind(this),
    );
    this.element.addEventListener(
      "turbo:before-frame-render",
      this.setNavInvisible.bind(this),
    );
    this.element.addEventListener(
      "turbo:frame-load",
      this.loadScrollPosition.bind(this),
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-fetch-request",
      this.saveScrollPosition.bind(this),
    );
    this.element.removeEventListener(
      "turbo:before-frame-render",
      this.setNavInvisible.bind(this),
    );
    this.element.removeEventListener(
      "turbo:frame-load",
      this.loadScrollPosition.bind(this),
    );
  }

  saveScrollPosition() {
    if (this.hasScrollableTarget) {
      this.scrollPosition = this.scrollableTarget.scrollLeft;
    }
  }

  setNavInvisible(event) {
    if (this.scrollPosition > 0) {
      const newFrame = event.detail.newFrame.querySelector("#subnav");
      if (newFrame) {
        newFrame.style.visibility = "hidden";
      }
    }
  }

  loadScrollPosition() {
    if (this.hasScrollableTarget) {
      this.scrollableTarget.scrollLeft = this.scrollPosition;
      this.scrollableTarget.style.visibility = "visible";
    }
  }
}
