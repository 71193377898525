import React, { useState } from "react";
import { BaseDialog } from "../../../global/components/BaseDialog";

function HowToPlayDialog({ modalTitle = "How to Play" }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <button
        className="flex items-center justify-center rounded-full px-4 py-2 font-medium text-white/80 hover:bg-white/20 hover:text-white"
        onClick={handleClick}
      >
        How To Play
      </button>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
        size={"md"}
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 px-2 pt-2 text-md sm:px-4">
      <ul className="list-none space-y-5 text-base">
        {/*<HelpListItem afterText="Playing Draft Phantom is as simple as 1, 2, 3" />*/}
        <HelpListItem
          bgColor="bg-[#aa8c58]"
          textColor="text-white"
          highlightText="Step 1"
          afterText={
            <>
              &nbsp; Select the 5 players you think will score the most AFL
              Fantasy points each week of finals
            </>
          }
        />
        <HelpListItem
          bgColor="bg-[#aa8c58]"
          textColor="text-white"
          highlightText="Step 2"
          afterText={<>&nbsp; Share your selections card with friends</>}
        />
        <HelpListItem
          bgColor="bg-[#aa8c58]"
          textColor="text-white"
          highlightText="Step 3"
          afterText={
            <>
              &nbsp; Check back after each match to see how you've climbed up
              the rankings!
            </>
          }
        />
      </ul>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default HowToPlayDialog;
