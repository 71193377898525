import React from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "../../../global/components/BaseDialog";

function PreselectionDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isPreselectionOpen,
    state.setIsPreselectionOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle=""
        modalBody={<ModalBody closeModal={closeModal} />}
        size="lg"
        showClose={false}
      />
    </>
  );
}

function ModalBody({ closeModal }) {
  return (
    <>
      <div className="text-md font-normal text-black sm:text-base">
        <div className="-mx-4 -mt-6 mb-4 flex items-center justify-center">
          <img
            className="mx-auto w-full"
            src={`/build/images/captains/brand/captains-welcome.png`}
            alt={`Calvin's Captains`}
          />
        </div>
        <h3 className="mb-4 text-center text-xl font-bold dark:text-gray-400 sm:text-2xl">
          Take the Captains Challenge
        </h3>
        {/*<div className="mb-4 px-2 text-black dark:text-gray-400">*/}
        {/*  Can you out-captain Calvin? Out-project Popowski?*/}
        {/*</div>*/}
        <div className="mb-6 px-2 text-center text-black dark:text-gray-400">
          Pick the 5 players you think will score the most{" "}
          <span className="hidden sm:inline">fantasy</span> points each week and
          become the <span className="sm:hidden">champ!</span>
          <span className="hidden sm:inline">Captain of Captains!</span>
        </div>
        <button
          onClick={closeModal}
          className="mx-auto mb-2 flex items-center rounded-lg bg-lime-600 px-5 py-2 text-center text-md font-medium text-white hover:bg-lime-700 disabled:opacity-50 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Let's go!
        </button>
      </div>
    </>
  );
}

export default PreselectionDialog;
