export async function canShareImage() {
  const dataUrl =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=";
  try {
    const blob = await (await fetch(dataUrl)).blob();

    const filesArray = [
      new File([blob], "test.png", {
        type: blob.type,
        lastModified: new Date().getTime(),
      }),
    ];

    const shareData = {
      files: filesArray,
    };

    return navigator.canShare(shareData);
  } catch (error) {
    return false;
  }
}
