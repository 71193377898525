import React from "react";
import useGameStore from "../lib/store/GameStore";
import { getPlayerById } from "../lib/utils/helpers";

export function ShareDiv({}) {
  const [showShareDiv, picks, lockState, round, currentRound, roundPoints] =
    useGameStore((state) => [
      state.showShareDiv,
      state.picks,
      state.lockState,
      state.round,
      state.currentRound,
      state.scores,
    ]);

  let total = 0;
  for (let pickNum = 1; pickNum <= 5; pickNum++) {
    const multiplier = 6 - pickNum;
    const playerId = picks[pickNum];

    const selectedPlayer = getPlayerById(playerId);

    const points = roundPoints && roundPoints[selectedPlayer?.id];
    if (points) {
      total += points * multiplier;
    }
  }

  return (
    showShareDiv && (
      <div style={{ marginTop: "1000px" }}>
        <div id="shareDiv" className="max-w-xs">
          <div className="bg-[url('/assets/images/finals/finals-bg.jpg')] bg-black bg-top bg-contain bg-no-repeat pb-2 pt-4 sm:max-w-sm">
            <div className="mx-auto mb-2 max-w-screen-sm text-center">
              <span className="mx-auto mb-px block max-w-36">
                <img
                  src="/build/images/finals/brand/finals-text.png"
                  alt="Finals Fantasy"
                />
              </span>
              <h3 className="font-saira text-sm font-bold uppercase tracking-tight text-white/90 antialiased dark:text-gray-200">
                {round?.name} Picks
              </h3>
            </div>
            <div className="mx-auto max-w-[16rem] rounded-md">
              <div
                id="leaderboard-inner"
                className="mx-auto w-full max-w-md space-y-1.5 rounded-sm bg-slate-200 p-1.5"
              >
                <ShareRows start={1} num={5} />
                {lockState === "LOCKED" &&
                  round &&
                  (round.number < currentRound.number || total > 0) && (
                    <div className="flex items-center justify-center py-0.5 font-saira text-lg font-bold uppercase leading-none text-slate-950">
                      {total} PTS
                    </div>
                  )}
              </div>
            </div>
            <div className="mx-auto mb-1 mt-1.5 flex max-w-[16rem] items-center justify-center">
              <h3 className="font-saira text-xxxs font-semibold uppercase tracking-tight text-white/70 antialiased dark:text-gray-200">
                Play at FinalsFantasy.com.au
              </h3>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

function ShareRow({ overallPick }) {
  const [players] = useGameStore((state) => [state.players]);

  const [picks, currentPick, round, roundPoints] = useGameStore((state) => [
    state.picks,
    state.currentPick,
    state.round,
    state.scores,
  ]);
  const onTheClock = false; //pick.overallPick === currentPick;

  const selectedPlayerId = picks[overallPick];
  const selectedPlayer = getPlayerById(selectedPlayerId);
  const points = roundPoints && roundPoints[selectedPlayer?.id];

  return (
    <>
      <div className={`flex w-full items-center transition-transform`}>
        <div className={`w-full ${onTheClock ? "bg-lime-500" : "bg-white"}`}>
          <div className="relative flex items-center">
            <div
              id="rank"
              className={`flex h-8 w-5 min-w-[1.75rem] flex-none items-center justify-center font-oswald text-lg font-bold tracking-tight ${
                onTheClock ? "text-white" : "text-slate-700"
              } antialiased`}
            >
              {overallPick}
            </div>
            <div id="name" className="flex flex-none items-center">
              {selectedPlayer && (
                <img
                  className="mr-1 h-8 w-8"
                  src={`/build/images/sport/players/${selectedPlayer.id}.png`}
                  // src={`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/2024014/${selectedPlayer.dataId}.png?im=Scale,width=.3,height=.3`}
                  alt={`${selectedPlayer.name} headshot`}
                />
              )}
              {selectedPlayer && (
                <div className="flex flex-col">
                  <div className="text-ellipsis text-left text-xs font-medium uppercase leading-none text-slate-950 antialiased">
                    {selectedPlayer.firstName}
                  </div>
                  <div className="text-ellipsis text-left font-saira text-md font-bold uppercase leading-none text-slate-950 antialiased">
                    {selectedPlayer.lastName}
                  </div>
                </div>
              )}
            </div>
            {points > 0 && (
              <div className="flex grow flex-col pr-0.5 text-right text-slate-950 antialiased">
                <span className="font-saira text-md font-bold uppercase leading-none">
                  {points}
                </span>
              </div>
            )}
            {selectedPlayer && points > 0 && (
              <div className="absolute right-0 top-0 z-20 flex items-center justify-center rounded-bl-sm bg-lime-600 px-0.5 pb-px pt-0.5 font-redhat text-[0.25rem] font-medium leading-none text-white">
                <span className="mr-0">
                  +{(5 - overallPick + 1) * points} PTS
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export function ShareRows({ order, start, num }) {
  const picks = [];
  for (let pickNum = start; pickNum < start + num; pickNum++) {
    picks.push(<ShareRow key={pickNum} overallPick={pickNum} />);
  }

  return picks;
}
