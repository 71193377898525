import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.form = this.element;
    this.inputs = this.form.querySelectorAll("input, select");
    this.checkFormValidity = this.checkFormValidity.bind(this);
    this.submitButton = this.form.querySelector("button[type='submit']");

    // Store the initial values of the input fields, select boxes, and radio groups
    this.initialValues = Array.from(this.inputs).map((input) => {
      if (input.type === "radio") {
        return (
          this.form.querySelector(`input[name="${input.name}"]:checked`)
            ?.value || null
        );
      } else {
        return input.value;
      }
    });

    this.inputs.forEach((input) => {
      input.addEventListener("input", this.checkFormValidity);
      input.addEventListener("change", this.checkFormValidity); // For select boxes
    });

    // Check if the form should start with the submit button disabled
    const startDisabled = this.form.hasAttribute("data-submit-start-disabled");
    if (startDisabled) {
      this.submitButton.disabled = true;
    } else {
      this.checkFormValidity(); // Initial check when the controller is connected
    }
  }

  disconnect() {
    this.inputs.forEach((input) => {
      input.removeEventListener("input", this.checkFormValidity);
      input.removeEventListener("change", this.checkFormValidity); // For select boxes
    });
  }

  checkFormValidity() {
    // Check if the form is valid
    const isValid = this.form.checkValidity();

    // Determine if change checking is disabled
    const disableCheckChanges = this.form.hasAttribute(
      "data-disable-check-changes",
    );

    // Check if any input value, select value, or radio group value has changed from its initial value
    const isChanged =
      !disableCheckChanges &&
      Array.from(this.inputs).some((input, index) => {
        if (input.type === "radio") {
          const checkedRadio = this.form.querySelector(
            `input[name="${input.name}"]:checked`,
          );
          return checkedRadio?.value !== this.initialValues[index];
        } else {
          return input.value !== this.initialValues[index];
        }
      });

    // Enable the submit button if the form is valid and (either changes are not checked or input value has changed)
    this.submitButton.disabled =
      !isValid || (!disableCheckChanges && !isChanged);
  }
}
