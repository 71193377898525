import useGameStore from "../store/GameStore";

export function getFixtureByTeamId(fixtures, teamId) {
  return fixtures.find((fixture) =>
    fixture.teams.some(
      (team) => team.team.id === teamId || team.opponent.id === teamId,
    ),
  );
}

export function getOpponentFromFixture(fixture, teamId) {
  if (!fixture) {
    return null;
  }

  return fixture.teams.find((team) => team.team.id !== teamId) || null;
}

export function isGameStarted(player) {
  const fixtures = useGameStore.getState().fixtures;
  const fixtureTeam = getFixtureByTeamId(fixtures, player.team.id);

  if (!fixtureTeam) {
    return false;
  }

  return (
    new Date(getFixtureByTeamId(fixtures, player.team.id).startAt) < new Date()
  );
}
