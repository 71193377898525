import { Controller } from "@hotwired/stimulus";

/*
 * Used to toggle warning message if user navigates away e.g.
 *
 * NOTE! Doesn't work with Turbo Frames because they use
 * turbo:click which isn't cancellable: https://github.com/hotwired/turbo/issues/610
 *
 * <form data-controller="beforeunload"
 *   data-beforeunload-warning-message-value="Are you sure you want to leave?">
 */
export default class extends Controller {
  static values = {
    warningMessage: {
      type: String,
      default: "Are you sure you want to leave this page?",
    },
  };

  connect() {
    // Ensure handlers are bound to the correct context
    this.forwardHandler = this.forwardHandler.bind(this);
    this.backHandler = this.backHandler.bind(this);

    window.addEventListener("beforeunload", this.forwardHandler);
    window.addEventListener("turbo:before-visit", this.forwardHandler);
    window.addEventListener("turbo:visit", this.backHandler);
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.forwardHandler);
    window.removeEventListener("turbo:before-visit", this.forwardHandler);
    window.removeEventListener("turbo:visit", this.backHandler);
  }

  backHandler(event) {
    if (event?.detail?.action === "restore") {
      this.leavingPage(event);
    }
  }

  forwardHandler(event) {
    this.leavingPage(event);
  }

  leavingPage(event) {
    if (!window.confirm(this.warningMessageValue)) {
      event.preventDefault();
      if (event.type === "beforeunload") {
        event.returnValue = "";
      }
    }
  }
}
