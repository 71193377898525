import useGameStore from "../store/GameStore";
import usePersistentStore from "../store/PersistentStore";

export function getPickResult(overallPick) {
  const pickResults = useGameStore.getState().pickResults;

  return pickResults ? pickResults[overallPick - 1]?.player : null;
}

export function getOverallPickForPlayerId(playerId) {
  const pickResults = useGameStore.getState().pickResults;

  const pickedAt = pickResults
    ? pickResults.find((pick) => pick.player?.id === playerId)
    : null;

  return pickedAt?.overallPick;
}

export function getPlayerById(selectedPlayerId) {
  const players = useGameStore.getState().players;

  return players
    ? players.find((player) => player.id === selectedPlayerId)
    : null;
}

export function getTeamByAbbr(teamAbbr) {
  const teams = useGameStore.getState().teams;
  return teams ? teams.find((team) => team.abbr === teamAbbr) : null;
}

export function calcScore(shareGroup) {
  const picks = usePersistentStore.getState().picks;
  const start = (shareGroup - 1) * 5 + 1;
  const end = shareGroup * 5;

  let score = 0;
  for (let overallPick = start; overallPick <= end; overallPick++) {
    const playerId = picks[overallPick];

    if (playerId) {
      const selectedAt = getOverallPickForPlayerId(playerId) || 999;
      const picksOff = Math.abs(overallPick - selectedAt);
      score += pickPoints(picksOff);
    }
  }

  return score;
}

export function calcGrade(score, shareGroup) {
  if (shareGroup === 1) {
    if (score >= 1000) {
      return "A++";
    } else if (score >= 900) {
      return "A+";
    } else if (score >= 800) {
      return "A";
    } else if (score >= 750) {
      return "B+";
    } else if (score >= 650) {
      return "B";
    } else if (score >= 600) {
      return "B-";
    } else if (score >= 550) {
      return "C+";
    } else if (score >= 500) {
      return "C";
    } else if (score >= 450) {
      return "C-";
    } else if (score >= 400) {
      return "D+";
    } else if (score >= 350) {
      return "D";
    } else if (score >= 200) {
      return "D-";
    }
    return "F";
  }

  if (shareGroup === 2) {
    if (score >= 400) {
      return "A++";
    } else if (score >= 350) {
      return "A+";
    } else if (score >= 300) {
      return "A";
    } else if (score >= 250) {
      return "B+";
    } else if (score >= 200) {
      return "B";
    } else if (score >= 175) {
      return "B-";
    } else if (score >= 150) {
      return "C+";
    } else if (score >= 125) {
      return "C";
    } else if (score >= 100) {
      return "C-";
    } else if (score >= 75) {
      return "D+";
    } else if (score >= 25) {
      return "D";
    }
    return "F";
  }

  if (shareGroup === 3) {
    if (score >= 300) {
      return "A++";
    } else if (score >= 250) {
      return "A+";
    } else if (score >= 200) {
      return "A";
    } else if (score >= 175) {
      return "B+";
    } else if (score >= 150) {
      return "B";
    } else if (score >= 125) {
      return "B-";
    } else if (score >= 100) {
      return "C+";
    } else if (score >= 75) {
      return "C";
    } else if (score >= 50) {
      return "C-";
    } else if (score >= 25) {
      return "D+";
    } else if (score >= 10) {
      return "D";
    }
    return "F";
  }

  return "TBC";
}

function pickPoints(picksOff) {
  const pointsMap = [100, 50, 25, 10];

  if (picksOff < pointsMap.length) {
    return pointsMap[picksOff];
  }

  return 0;
}
